<header class="page-header flex d-flex flex-row justify-content-center align-items-center">
  <div class="items_container row w-100">
    <div id="divlogo" class="col">
      <a [href]="externalLinks.logo" class="flex d-flex justify-content-center">
        <img
          ngSrc="assets/img/Logo-negativa.svg"
          [height]="'auto'" [width]="'auto'"
          class="page-header__logo"
          alt="PSA logo"
        />
      </a>
    </div>
  </div>
</header>

<nav class="page-header__nav" [class.show]="menuModileShow">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-2 close__btn">
        <img (click)="menuModileShow=false" width="30" height="30" alt="botão para fechar o menu" ngSrc="../../../assets/icons/close-black.svg" />
			</div>
		</div>
		<div style="height: 30px;"></div>
		<div class="row align-items-center logo" [class.d-none]="activeSubMenuItems.length > 0">
			<div class="col text-center">
        <img ngSrc="assets/img/logo-black.svg" height="50" [width]="'auto'" class="page-header__nav__img d-flex justify-content-center" alt="PSA logotipo">

				<a class="page-header__nav__item d-flex justify-content-center" [href]="externalLinks.base">
					<span>Base de especialistas</span>
				</a>

				<a class="page-header__nav__item d-flex justify-content-center" [href]="externalLinks.education">
					<span>Educação Corporativa</span>
				</a>

				<a class="page-header__nav__item d-flex justify-content-center" [href]="externalLinks.news">
					<span>PSA NEWS</span>
				</a>
			</div>
		</div>
	</div>
	<div class="container-fluid">
		<div class="row align-items-center justify-content-center">
			<div class="col text-center">
				<div class="page-header__nav__footer">
					<a href="https://instagram.com/profissionaissa" class="page-header__nav__footer__link"><img height="30" width="30" ngSrc="assets/icons/instagram_circle.svg" alt="Logotipo do Instagram" /></a>
					<a href="https://linkedin.com/company/profissionais-sa" class="page-header__nav__footer__link"><img height="30" width="30" ngSrc="assets/icons/linkedin_circle.svg" alt="Logotipo do Linkedin" /></a>
					<a href="https://youtube.com/profissionaissa" class="page-header__nav__footer__link"><img height="30" width="30" ngSrc="assets/icons/youtube_circle.svg" alt="Logotipo do Youtube" /></a>
				</div>
			</div>
		</div>
	</div>
</nav>
