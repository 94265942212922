import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'psa-speakers-list',
  templateUrl: './speakers-list.component.html',
  styleUrls: ['./speakers-list.component.css'],
})
export class SpeakersListComponent implements OnInit {
  @Input('speakers') speakers: any;
  utmParams: any = {};

  constructor(
    private router: Router,
    private activetedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
  }

  public navigateToRelacionadoProfile(slug: string, temas_id: string): void {
    const extras: NavigationExtras = {
      state: {
        temas_id,
      },
      queryParamsHandling: "merge",
      queryParams: this.utmParams,
    };

    this.router.navigate(['/palestrante/', slug], extras);
  }

  public getMinibio(text: string, maxLength: number = 100) {
    return text.length > maxLength
      ? text.substring(0, maxLength - 4) + ' ...'
      : text;
  }
}
