<section class="topo" style="padding-top: 100px">
  <div class="row palestra__container" *ngIf="palestraItem">
    <div class="topo__header col-lg-12 p-3 text-center">
      <h1 class="h1__topo">
        <span>PALESTRAS DE</span><br>
        <p>{{profissional.nome_palestrante}}</p>
      </h1>
    </div>
    <div class="col-12 col-lg-6 p-3">
      <lite-youtube [attr.videoid]="videoId">
        <a class="lite-youtube-fallback" [href]="linkVideo">Palestra {{ palestraItem.nome}}"</a>
      </lite-youtube>
    </div>
    <div class="palestra__info col-12 col-lg-6 p-3">
      <h2>{{ palestraItem.nome }}</h2>
      <p>{{ palestraItem.descricao }}</p>
    </div>
  </div>
</section>


<div class="d-flex justify-content-center" *ngIf="loading">
  <img ngSrc="../../../assets/img/simple_loading.gif" alt="loading" />
</div>

<div class="profissional">
  <section class="profissional__palestras" *ngIf="palestras?.length > 0">
    <div class="profissional__palestras__title">
      <p>Palestras de</p>
      <h2>{{profissional.nome_palestrante}}</h2>
    </div>

    <div class="profissional__palestras__container">
      <div
        *ngFor="let palestra of palestras"
        class="profissional__palestras__items"
        (click)="navigateProposal(palestra)"
      >
        <div>
          <h2>{{ palestra.nome }}</h2>
          <p>{{ palestra.descricao }}</p>
        </div>
        <a style="float: inline-end">Saiba mais</a>
      </div>
    </div>

    <div class="profissional__palestras__footer">
      <button (click)="goto()" class="profissional__orcamento__button">
        Solicitar orçamento
      </button>
    </div>
  </section>
</div>

<div class="profissional">
  <section class="profissional__reasons mt-4" *ngIf="profissional.reasons">
    <h2 class="profissional__temas__title mt-3">Por que contratar {{profissional.nome_palestrante}}</h2>
    <div class="profissional__reasons__content mt-5 text-left" [innerHTML]="profissional.reasons"></div>
    <div class="profissional__palestras__footer">
      <button (click)="goto()" class="profissional__orcamento__button">
        Solicitar orçamento
      </button>
    </div>
  </section>
</div>

<psa-asked-questions *ngIf="profissional.id" [palestrante]="profissional"></psa-asked-questions>

<psa-relacionados-carousel [relacionados]="profissionaisRelacionados"></psa-relacionados-carousel>
