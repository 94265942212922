import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SpeakerService {
  constructor(public http: HttpClient) {}

  public async getSpeakers(slug: string): Promise<any> {
    const res = await this.http.get<any>(`${environment.API_SPEAKERS}palestrante/${slug}`).toPromise();

    return res;
  }

  public async getPalestras(id: string): Promise<any> {
    const res = await this.http.get<any>(`${environment.API_SPEAKERS}palestras/${id}`).toPromise();

    return res;
  }
  public async getPalestrasBySlug(slug: string): Promise<any> {
    const res = await this.http.get<any>(`${environment.API_SPEAKERS}palestras/slug/${slug}`).toPromise();

    return res;
  }

  public async getDepoimentos(id: string): Promise<any> {
    const res = await this.http.get<any>(`${environment.API_SPEAKERS}depoimentos/${id}`).toPromise();

    return res;
  }

  public async getRelacionados(id: string, themes: Array<number>): Promise<any> {
    const res = await this.http.put<any>(`${environment.API_SPEAKERS}relacionados/${id}`, { themes }).toPromise();

    return res;
  }

  searchSpeakers(filter: string) {
    return this.http.get(`${environment.API_V2_URL}proposal/filter/term?term=${filter}&format=1`)
  }

  savePesquisa(value: string) {
    const body = { value };
    return this.http.post(`${environment.API_V2_URL}site/pesquisas`, body);
  }
}
