import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpeakerService } from 'src/app/services/speaker.service';

@Component({
  selector: 'psa-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  searchQuery: string = '';
  searchInput: string = '';
  speakers: any = [];
  loading: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private palestrantesService: SpeakerService,
  ) {
    router.queryParamMap.subscribe(
      (result: any) => {
        this.searchQuery = result.params.search;
      }
    )
  }

  ngOnInit(): void {
    this.getSpeakers();
  }

  getSpeakers() {
    this.loading = true;
    this.savePesquisa(this.searchQuery);
    this.palestrantesService.searchSpeakers(this.searchQuery).subscribe(
      (result: any) => {
        this.speakers = result.body;
        this.loading = false;
      }
    );
  }

  searchSpeakers() {
    this.loading = true;
    this.savePesquisa(this.searchInput);
    this.palestrantesService.searchSpeakers(this.searchInput).subscribe(
      (result: any) => {
        this.speakers = result.body;
        this.loading = false;
      }
    );
  }

  savePesquisa(pesquisa: string) {
    this.palestrantesService.savePesquisa(pesquisa).subscribe(
      (result: any) => {
      }
    );
  }
}
