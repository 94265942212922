import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'psa-footer-white',
  templateUrl: './footer-white.component.html',
  styleUrls: [
    './footer-white.component.css',
    '../../../assets/css/bootstrap.css',
    '../../../assets/css/index.css',
    '../../../assets/css/comuns.css',
    '../../../assets/css/theme-animate.css',
  ]
})
export class FooterWhiteComponent implements OnInit {
  utmParams: string = '?';
  externalLinks: any = {
    home: `https://profissionaissa.com`,
    talks: `https://profissionaissa.com/palestrantes-e-especialistas`,
    policy: `https://profissionaissa.com/politica-de-privacidade`,
    terms: `https://profissionaissa.com/termos-de-uso-e-condicoes-gerais`,
    diversity: `https://profissionaissa.com/palestra-diversidade-e-inclusao`,
    entrepreneurship: `https://profissionaissa.com/palestra-empreendedorismo`,
    leadership: `https://profissionaissa.com/palestra-lideranca`,
    motivation: `https://profissionaissa.com/palestra-motivacao`,
    health: `https://profissionaissa.com/palestra-saude`,
    sustainability: `https://profissionaissa.com/palestra-sustentabilidade`,
    sales: `https://profissionaissa.com/palestra-vendas`
  }

  constructor(
    private router: ActivatedRoute,
  ) {
    this.router.queryParamMap.subscribe(
      (result: any) => {
        if(Object.keys(result.params).length === 0) {
          this.utmParams = '?utm_source=organico';

          this.externalLinks = {
            home: `https://profissionaissa.com${this.utmParams}`,
            talks: `https://profissionaissa.com/palestrantes-e-especialistas${this.utmParams}`,
            policy: `https://profissionaissa.com/politica-de-privacidade${this.utmParams}`,
            terms: `https://profissionaissa.com/termos-de-uso-e-condicoes-gerais${this.utmParams}`,
            diversity: `https://profissionaissa.com/palestra-diversidade-e-inclusao${this.utmParams}`,
            entrepreneurship: `https://profissionaissa.com/palestra-empreendedorismo${this.utmParams}`,
            leadership: `https://profissionaissa.com/palestra-lideranca${this.utmParams}`,
            motivation: `https://profissionaissa.com/palestra-motivacao${this.utmParams}`,
            health: `https://profissionaissa.com/palestra-saude${this.utmParams}`,
            sustainability: `https://profissionaissa.com/palestra-sustentabilidade${this.utmParams}`,
            sales: `https://profissionaissa.com/palestra-vendas${this.utmParams}`
          }
          this.utmParams = '?';
        }

        for (let key in result.params) {
          this.utmParams = this.utmParams + key + '=' + result.params[key] + '&';

          this.externalLinks = {
            home: `https://profissionaissa.com${this.utmParams}`,
            talks: `https://profissionaissa.com/palestrantes-e-especialistas${this.utmParams}`,
            policy: `https://profissionaissa.com/politica-de-privacidade${this.utmParams}`,
            terms: `https://profissionaissa.com/termos-de-uso-e-condicoes-gerais${this.utmParams}`,
            diversity: `https://profissionaissa.com/palestra-diversidade-e-inclusao${this.utmParams}`,
            entrepreneurship: `https://profissionaissa.com/palestra-empreendedorismo${this.utmParams}`,
            leadership: `https://profissionaissa.com/palestra-lideranca${this.utmParams}`,
            motivation: `https://profissionaissa.com/palestra-motivacao${this.utmParams}`,
            health: `https://profissionaissa.com/palestra-saude${this.utmParams}`,
            sustainability: `https://profissionaissa.com/palestra-sustentabilidade${this.utmParams}`,
            sales: `https://profissionaissa.com/palestra-vendas${this.utmParams}`
          }
        }
      }
    );
  }

  ngOnInit() {}
}
