import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(private router: Router) {}

  updateCanonical(url: string): void {
    const head = document.getElementsByTagName('head')[0];
    let link: HTMLLinkElement = head.querySelector(`link[rel='canonical']`) || document.createElement('link');

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    head.appendChild(link);
  }

  initCanonicalUpdater(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const canonicalUrl = window.location.href;
      this.updateCanonical(canonicalUrl);
    });
  }
}
